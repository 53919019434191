









































































































































































































































































































import moment from "moment";
import Vue from "vue";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import MaDatePicker from "@/components/MaDatePicker/MaDatePicker.vue";
import PdfViewGenerals from "@/components/PDF/PdfViewGenerals.vue";
import FirstPayments from "@/views/Configurations/FirstPayments.vue";
export default Vue.extend({
  name: "payments-generals",
  components: { MaDatePicker, PdfViewGenerals, FirstPayments },
  data() {
    return {
      tabselect: null,
      fail: false,

      footerProps: { "items-per-page-options": [50, 100, 500] },
      modaldate: false,
      filterEmployees: "",
      filterPatients: "",
      filterRanges: "this-year",
      filterTypes: "",
      dateFrom: "",
      options: {},
      expanded: [],

      dateTo: "",
      bodyreq: {
        patientUuid: null,
        employeeUuid: null,
        type: "",
        status: "CONFIRMED",
        dates: ["", ""],
        range: {
          limit: 100,
          offset: 0,
        },
      },
      headers: [
        { text: "TYPE", value: "type", sortable: false },
        { text: "Employee", value: "employee", sortable: false },
        { text: "AMOUNT $", value: "amount", sortable: false, align: "end" },
        { text: "STATUS", value: "status", sortable: false, align: "center" },
        { text: "Payment date", value: "paymentDate", sortable: false },
        { text: "", value: "data-table-expand", sortable: false },
      ],
      intervals: [
        { label: "Today", value: "today" },
        { label: "Yesterday", value: "yesterday" },
        { label: "This week", value: "this-week" },
        { label: "Last week", value: "last-week" },
        { label: "This month", value: "this-month" },
        { label: "Last month", value: "last-month" },
        { label: "This year", value: "this-year" },
        { label: "Last year", value: "last-year" },
      ],
      types: [
        { label: "Cash", value: "CASH" },
        { label: "Card Reader", value: "External_Card_Reader" },
        { label: "Zelle", value: "Zelle" },
        { label: "Card", value: "Stripe" },
        { label: "PayPal", value: "Paypal" },
        { label: "Square", value: "Square" },
        { label: "Alphaeon", value: "Alphaeon" },
        { label: "United Medical Credit", value: "UnitedMedicalCredit" },
        { label: "CareCredit", value: "CareCredit" },
      ],
    };
  },
  computed: {
    ...mapState("crmMedicFormModule", ["patients"]),
    ...mapState("crmEmployeeModule", ["workers"]),
    ...mapState("crmConfigurationsModule", ["payments", "loading"]),
    ...mapState(["totalitems"]),
    ...mapGetters(["getpatientN"]),
    validClean() {
      if (
        ((this as any).dateFrom != null &&
          (this as any).dateFrom != undefined &&
          (this as any).dateFrom != "") ||
        ((this as any).dateTo != null &&
          (this as any).dateTo != undefined &&
          (this as any).dateTo != "")
      ) {
        return true;
      }
      return false;
    },

    intervarThisYear() {
      const from = moment().month(0).date(1).startOf("day").utc();
      const to = moment().utc();

      return {
        date1: from.toISOString(),
        date2: to.toISOString(),
      };
    },
  },
  watch: {
    fail(val) {
      if (val) {
        (this as any).bodyreq.status = "UNCONFIRMED";
      } else {
        (this as any).bodyreq.status = "CONFIRMED";
      }
      (this as any).filterPayments();
    },
    dateFrom(val: string) {
      if (
        val !== null &&
        (this as any).dateTo !== null &&
        (this as any).dateTo !== ""
      ) {
        (this as any).filterRanges = "custom-select";
        (this as any).filterPayments();
      }
    },
    dateTo(val: string) {
      if (
        val !== null &&
        (this as any).dateFrom !== null &&
        (this as any).dateFrom !== ""
      ) {
        (this as any).filterRanges = "custom-select";
        (this as any).filterPayments();
      }
    },
    options(val) {
      if (val != {}) {
        (this as any).filterPayments();
      }
    },
  },
  methods: {
    ...mapActions("crmMedicFormModule", ["actListPatientsFilter"]),
    ...mapActions("crmEmployeeModule", ["actGetEmployees"]),
    ...mapActions("crmConfigurationsModule", [
      "actGetPaymentSumary",
      "actGetPayments",
    ]),
    ...mapMutations(["mutReqRange"]),
    ...mapMutations("crmMedicFormModule", ["mutProcedure"]),

    getEmployeeName(item: any) {
      if (item.createdBy == null) {
        return "-";
      }
      return item.createdBy.fullname;
    },

    setIntervar(interval: string) {
      (this as any).filterRanges = interval;
      (this as any).filterPayments();
    },
    showDetails() {
      console.log("details");
    },

    prettyDescrip(descrip: string) {
      if (descrip.length > 28) {
        return descrip.substr(0, 28);
      } else {
        return descrip;
      }
    },

    filterPayments() {
      const { page, itemsPerPage }: any = (this as any).options;
      const itmper = itemsPerPage != -1 ? itemsPerPage : 50;
      let body = (this as any).bodyreq;
      body.range = {
        limit: itmper,
        offset: (page - 1) * itmper,
      };
      (this as any).mutReqRange(body.range);
      switch ((this as any).filterRanges) {
        case "this-year":
          body.dates = [
            (this as any).intervarThisYear.date1,
            (this as any).intervarThisYear.date2,
          ];
          break;
        case "custom-select":
          body.dates = [
            moment((this as any).dateFrom)
              .startOf("day")
              .utc()
              .toISOString(),
            moment((this as any).dateTo)
              .endOf("day")
              .utc()
              .toISOString(),
          ];
          break;
      }
      body = (this as any).cleanNull(body);
      (this as any).actGetPayments(body);
    },

    cleanNull(obj: any) {
      for (var propName in obj) {
        if (
          obj[propName] === null ||
          obj[propName] === undefined ||
          obj[propName] === ""
        ) {
          delete obj[propName];
        }
      }
      return obj;
    },
    resetFilter(value?: string) {
      switch (value) {
        case "custom-select":
          (this as any).filterRanges = "this-year";

          break;
        case "patients":
          (this as any).filterPatients = "";

          break;
        case "types":
          (this as any).filterTypes = "";

          break;
        case "employees":
          (this as any).filterEmployees = "";
          break;

        default:
          (this as any).filterPatients = "";
          (this as any).filterEmployees = "";
          (this as any).filterRanges = "this-year";

          (this as any).fail = false;
          (this as any).filterTypes = "";
          (this as any).bodyreq = {
            status: "CONFIRMED",
            patientUuid: null,
            employeeUuid: null,
            dates: ["", ""],
          };

          break;
      }
      (this as any).filterPayments();
    },
    prettyDate(date: string): string {
      if (date === null || date === "") return "";

      return moment(date).format("dddd D MMMM YYYY, h:mm:ss a");
    },
    typesShow(type: string) {
      if (type == "External_Card_Reader") {
        return "Card Reader";
      }
      if (type == "Stripe") {
        return "Card";
      }
      if (type == "UnitedMedicalCredit") {
        return "Medical Credit";
      }
      return type;
    },

    gotoPatient(item: any) {
      (this as any).mutProcedure(item.procedure);
      this.$router.push({
        name: "Patient ddetails",
        params: { uuid: item.procedure.patient.uuid },
      });
    },
  },
  mounted() {
    (this as any).actListPatientsFilter();
    (this as any).actGetEmployees();
    //  (this as any).setIntervar((this as any).filterRanges);
    (this as any).bodyreq.status = "CONFIRMED";
  },
});
